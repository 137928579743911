import React, { useEffect, useRef, useState } from 'react';
import { Affix, Alert, Row, Col, Typography } from 'antd';
import {getCookies, setCookies } from '@/helpers/utils';

const AppDownloadBanner = ({autoClose, delay}) => {
    const [visible, setVisible] = useState(process.env.NEXT_PUBLIC_SHOW_APP_BANNER == 'true' ? true : false);
    const [showAppBanner, setShowAppBanner] = useState(false);

    const onClose = () => {
        setVisible(false);
        setCookies('showAppBanner', false);
    };

    useEffect(() => {
        const showAppBanner = getCookies('showAppBanner') == 'true';
        if (showAppBanner) {
            console.log('cookie', getCookies('showAppBanner') );
            setShowAppBanner(true);
        }

        if(autoClose){
            const timer = setTimeout(() => {
              setVisible(false);
            }, delay ?? 5000); 
        
            return () => {
              clearTimeout(timer);
            };
        }
      }, [autoClose, delay]);

    return (
        showAppBanner && (process.env.NEXT_PUBLIC_PLAY_STORE || process.env.NEXT_PUBLIC_PLAY_STORE) &&
        <Affix offsetBottom={40} style={{ zIndex: 10 }} className={`${visible ? 'opacity-1':'opacity-0'} transition-all duration-500`}>
            <Alert
                message={
                        <Row gutter={[8, 8]} className="w-full flex justify-center items-center w-full">
                            <Col span={24} lg={10} className='text-center'>
                                <Typography.Title className='text-base lg:text-xl text-slate-700 mb-0'>Download our app for a better experience!</Typography.Title>
                            </Col>
                            <Col span={8} sm={5} md={4} lg={4} xl={3}>
                                <a href={process.env.NEXT_PUBLIC_PLAY_STORE}>
                                    <img width={'100%'} src={'/images/playstore.png'}/>
                                </a>
                            </Col>
                            <Col span={8} sm={5} md={4} lg={4} xl={3}>
                                <a href={process.env.NEXT_PUBLIC_APP_STORE} >
                                    <img width={'100%'} src={'/images/appstore.png'}/>
                                </a>
                            </Col>
                        </Row>
                }
                type="info"
                closable
                className='mx-3'
                afterClose={onClose}
                
            />
        </Affix>
    );
};

export default AppDownloadBanner;
