import { Config } from "config/Constants"
import axios from "axios";


export const Axios = axios.create({
    baseURL: Config.url.API_URL,
    headers: {
        'device-type': '30'
    }
    // xsrfCookieName: "csrftoken",
    // xsrfHeaderName: "X-CSRFToken",
    // crossdomain: true,
    // headers: {
    //     //   'X-User-Agent': `*`,
    //     //   'Content-Type':`multipart/form-data`
    //     "X-User-Agent": `nevendo-web`,
    //     //   'Access-Control-Allow-Headers':'origin, content-type, accept'
    // },
});


export async function getDashboard(t: string, eventId: number, type: number) {
    return await Axios.get(eventId + (type == 20 ? "/dashboard" : "/web_dashboard"),
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getExhibitorProfile(t: string, eventId: number) {
    return await Axios.get(eventId + "/profile",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function updateExhibitorProfile(t: string, eventId: number, action: string, formdata: any) {
    return await Axios.post(eventId + action, formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function getExhibitorDownloads(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/informations",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function fetchExhibitorDownloadsCertificate(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/certificate",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function fetchparticipationCertificate(t: string, eventId: number) {
    return await Axios.get(eventId + "/participation_certificate",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function fetchSupport(t: string, eventId: number) {
    return await Axios.get(eventId + "/support",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function fetchExhibitorProducts(t: string, eventId: number, categoryId: number) {
    return await Axios.get(eventId + "/otherforms",
        {
            params: { category_id : categoryId },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function fetchExhibitorCart(t: string, eventId: number) {
    return await Axios.get(eventId + "/otherforms/cartlist",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}


export async function addProduct2Cart(t: string, eventId: number, id: number, count: number, flag: number) {

    return await Axios.post(eventId + "/otherforms",
        {
            "product_id": id,
            "product_quantity": count,
            'flag': flag
        },
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e)
}

export async function deleteProductCart(t: string, eventId: number, id: number) {
    return await Axios.delete(eventId + "/otherforms/cartlist/" + id,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e)
}

export async function getCartList(t: string, eventId: number) {
    return await Axios.get(eventId + "/otherforms/cartlist",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e)
}


export async function checkoutCart(t: string, eventId: number) {
    return await Axios.post(eventId + "/otherforms/cartlist/checkout", {},
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e)
}

export async function getOrdersList(t: string, eventId: number, page: number, search: string) {
    return await Axios.get(eventId + "/orders",
        {
            params: { page, search },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getOrderDetails(t: string, eventId: number, id: number) {
    return await Axios.get(eventId + "/orders/" + id,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function cancelOrderItem(t: string, eventId: number, id: number) {
    return await Axios.delete(eventId + "/orders/" + id,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}


export async function getBoothDesigns(t: string, eventId: number) {
    return await Axios.get(eventId + "/boothdesign",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function createBoothDesigns(t: string, eventId: number, formdata: any) {
    return await Axios.post(eventId + "/boothdesign", formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}


export async function changeExhibitorPassword(t: string, eventId: number, formdata: any) {
    return await Axios.post(eventId + "/changepassword", formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function getDirectoryMapBoard(t: string, eventId: number) {
    return await Axios.get(eventId + "/directorymapboard",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function updateForms(t: string, eventId: number, action: string, formdata: any) {
    return await Axios.post(eventId + action, formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function getPowerOrders(t: string, eventId: number) {
    return await Axios.get(eventId + "/powerorder",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function updatePowerOrders(t: string, eventId: number, formdata: any) {
    return await Axios.post(eventId + "/powerorder", formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}


export async function getProductIndexList(t: string, eventId: number) {
    return await Axios.get(eventId + "/productindex",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}


export async function postProductIndexList(t: string, eventId: number, data: any) {
    return await Axios.post(eventId + "/productindex",
        data,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}


export async function reopenForms(t: string, eventId: number, form_type: any, form_id: any) {
    return await Axios.post(eventId + '/formstatus', {
        'form_type': form_type,
        'form_group_id': form_id,
    },
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function updateDynamicForms(t: string, eventId: number, action: string, formdata: any) {
    return await Axios.post(eventId + action, formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function getDynamicFormData(t: string, eventId: number, action: string) {
    return await Axios.get(eventId + action,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function getDynamicFormMasters(t: string, eventId: number | string, formName: string) {
    return await Axios.get(eventId + '/get_form/' + formName,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(x => x.data)
}


export async function getExhibitorBadges(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/badge",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getExhibitorTeam(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/exhibitorteam",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function sendExhibitorBadges(t: string, eventId: number, id: number | string, type: String) {
    return await Axios.get(eventId + "/" + type + "/" + id + "/send",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getPageDetails(t: string, eventId: number | string, pageId: number) {
    return await Axios.get(eventId + '/get_page/' + pageId, {
        headers: {
            'Authorization': 'Bearer ' + t,
            'content-type': 'multipart/form-data',
        }
    }).then(x => x.data)
}


export async function getDynamicForm2(t: string, eventId: number | string, formKey: string) {
    return await Axios.get(eventId + '/dynamic_form/' + formKey, {
        headers: {
            'Authorization': 'Bearer ' + t,
            'content-type': 'multipart/form-data',
        }
    }).then(x => x.data)
}

export async function updateDynamicForms2(t: string, eventId: number, formdata: any) {
    return await Axios.post(eventId + "/dynamic_form", formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'Content-Type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function getDynamicForm2Data(t: string, eventId: number | string, formKey: string) {
    return await Axios.get(eventId + '/dynamic_form_data/' + formKey, {
        headers: {
            'Authorization': 'Bearer ' + t,
            'content-type': 'multipart/form-data',
        }
    }).then(x => x.data)
}
export async function getDynamicFormGroups(t: string, eventId: number | string) {
    return await Axios.get(eventId + '/all_dynamic_forms', {
        headers: {
            'Authorization': 'Bearer ' + t,
            'content-type': 'multipart/form-data',
        }
    }).then(x => x.data)
}

export async function getExhibitorServicePass(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/service_pass",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getMenus(t: string, eventId: number) {
    return await Axios.get(eventId + "/get_form/menus",
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getAIMatches(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/aimatch",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getExhibitorBooking(t: string, eventId: number, id: number) {
    return await Axios.get(eventId + "/b2bbooking/" + id,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}



export async function getB2bMeet(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/b2bmeet",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getB2bBookedSlot(t: string, eventId: number, date: string) {
    return await Axios.get(eventId + "/b2bbookedslot/" + date,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function bookedMeeting(t: string, eventId: number, formdata: FormData) {
    return await Axios.post(eventId + "/bookmeeting", formdata,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}
export async function getB2bbookedDetails(t: string, eventId: number, page: number) {
    return await Axios.get(eventId + "/b2bbookeddetails",
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}


export async function cancelB2bMeet(t: string, eventId: number, id: number) {
    return await Axios.delete(eventId + "/b2bbookeddetails/" + id,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getFavourite(t: string, eventId: number, exhibitorId: number, favorite: number) {

    return await Axios.post(eventId + "/exhibitor/" + exhibitorId + "/aimatch",
        {
            'favorite': favorite
        },
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getRelevant(t: string, eventId: number, exhibitorId: number, relevant: number) {
    return await Axios.post(eventId + "/exhibitor/" + exhibitorId + "/aimatch",
        {
            'relevant': relevant
        },
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getProgrammes(t: string, eventId: number, date: string) {
    return await Axios.get(eventId + "/programmes?device_type=30",
        {
            params:{date},
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getProgramDetails(t: string, eventId: number, pgmID: number) {
    return await Axios.get(eventId + "/programmes/" + pgmID,
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}
export async function getProgrammesZoom(t: string, eventId: number, pgmID: number) {
    return await Axios.post(eventId + "/programmes/" + pgmID + "/zoom", {},
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getExhibitorDelegates(t: string, eventId: number, page: number, key: String) {
    return await Axios.get(eventId + "/" + key,
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)
}

export async function getDynamicData(t: string, eventId: number, page: number, formName: string) {
    return await Axios.get(eventId + "/" + formName,
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)

}

export async function getDynamicTableColumns(t: string, eventId: number, page: number, formName: string) {
    return await Axios.get(eventId + '/get_form/' + formName,
        {
            params: { page },
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }).then(e => e.data)

}

export async function deleteDynamicFormData(t: string, eventId: number, action: string) {
    return await Axios.delete(eventId + "/" + action,
        {
            headers: {
                'Authorization': 'Bearer ' + t,
                'content-type': 'multipart/form-data',
            }
        }).then(e => e.data)
}

export async function fetchExhibitorsDetails(
    t: string,
    eventId: number,
    id: number
) {
    return await Axios.get(eventId + "/exhibitors/" + id, {
        headers: {
            Authorization: "Bearer " + t,
        },
    }).then((e) => e.data);
}

export async function fetchSponsors(t: string, eventId: number) {
    return await Axios.get(eventId + "/sponsors", {
        headers: {
            Authorization: "Bearer " + t,
        },
    }).then((e) => e.data);
}


export async function updateB2BBooking(t: string, eventId: number, book_id: number, status: number, rejection_note: string) {
    return await Axios.post(eventId + "/b2bbookingupdate/" + book_id, {
        status: status,
        rejection_note: rejection_note
    },
        {
            headers: {
                Authorization: "Bearer " + t,
            },
        }).then((e) => e.data).catch(err => { throw err });
}


export async function bookB2B(t: string, eventId: number, delegate_Id: number, team: number, venue: string, venue_others: string, bookingdate: string, timeslot: string, comments: string, file: File, exhibitorId: number) {
    const formData = new FormData();
    formData.append('exhibitorid', exhibitorId.toString() ?? '0');
    formData.append('team', delegate_Id.toString());
    formData.append('venue', venue);
    formData.append('venue_others', venue_others);
    formData.append('bookingdate', bookingdate);
    formData.append('timeslot', timeslot);
    formData.append('comments', comments);
    formData.append('file', file);

    return await Axios.post(eventId + "/bookmeeting", formData,
        {
            headers: {
                Authorization: "Bearer " + t,
                'Content-Type': 'multipart/form-data'
            },
        }).then((e) => e.data).catch(err => { throw err });
}

export async function cancelB2bMeeting(t: string, eventId: number, id: number, rejection_note: string) {
    return await Axios.post(eventId + "/b2bbookingcancel/" + id,
        {
            rejection_note: rejection_note,
        },
        {
            headers: {
                'Authorization': 'Bearer ' + t
            }
        }
    ).then(e => e.data)
}

export async function getVideoBites(t: string, eventId: number) {
    return await Axios.get(eventId + "/videobites", {
        headers: {
            Authorization: "Bearer " + t,
        },
    }).then((e) => e.data).catch(e => { throw e });
}