const prod = {
    url: {
        // API_URL: "http://192.168.1.9:8000/api/nevendo/",
        // API_URL: "https://wsc.nevendo.in/api/nevendo/",
        // API_URL: "http://192.168.1.5:8001/api/nevendo/",
        // API_URL: "https://oem.nevendo.in/api/nevendo/",
        // API_URL: "https://expo.nevendo.in/api/nevendo/",
        API_BASE_URL: process.env.NEXT_API_URL,
        API_URL: process.env.NEXT_API_URL+'/nevendo',

    },
    MenuItems: [
        {
            "href": "/",
            "title": "Home"
        },
        // {
        //     "href": "/programs",
        //     "title": "Programs"
        // },
        // {
        //     "href": "/speakers",
        //     "title": "Speakers"
        // },
        // {
        //     "href": "/exhibitors",
        //     "title": "Exhibitors"
        // },

        {
            "href": "/exhibitors/profile",
            "title": "Exhibitor Profile"
        },
        {
            "href": "/exhibitors/uploads/files",
            "title": "Important Information "
        },
        {
            "href": "/exhibitors/uploads/certificates",
            "title": "Certificates & Invoices"
        },
        {
            "href": "/exhibitors/product_index",
            "title": "Product Index"
        },
        {
            "href": "/exhibitors/forms/10",
            "title": "Brand Promotion"
        },
        {
            "href": "/exhibitors/forms",
            "title": "Forms"
        },

        {
            "href": "/exhibitors/cart",
            "title": "Additional Requirement"
        },
        {
            "href": "/exhibitors/orders",
            "title": "Orders"
        },
        {
            "href": "/exhibitors/booth",
            "title": "Booth Design"
        },
        // {
        //     "href": "/exhibitors/directory_map_board",
        //     "title": "Directory Map Board"
        // },
        {
            "href": "/exhibitors/power_orders",
            "title": "Power Orders"
        },
        {
            "href": "/exhibitors/change_password",
            "title": "Change Password"
        },
        {
            "href": "/exhibitors/badge",
            "title": "Exhibitor Badge"
        },
        {
            "href": "/exhibitors/service_pass",
            "title": "Service Pass"
        },
        {
            "href": "/exhibitors/support",
            "title": "Help & Support"
        },

        // {
        //     "href": "/myprofile",
        //     "title": "My Profile"
        // },
        // {
        //     "href": "/delegates",
        //     "title": "Delegates"
        // },

        // {
        //     "href": "/downloads",
        //     "title": "Downloads"
        // },
        // {
        //     "href": "/logout",
        //     "title": "Logout"
        // }

    ]

};
export const Config = prod;


export const CONSTANTS = {
    '404': 'Sorry, The page you are looking for can\'t be found',
    '403': 'You don\'t have access'
}

export const COLORS = {
    'abstract': {
        '10': '#228B22',
        '20': '#1520A6',
        '30': '#FF0000',
        '40': '#FFA500',
        '40': '#EE9F27',
        '': '#000'
    }

}