import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#026fa6',
  },
  components: {
    Tabs: {
      itemSelectedColor: 'white',
      cardBg: '#e3e3e3',
      colorBgContainer: '#f7f7f7',
    }
  }
};

export default theme;
