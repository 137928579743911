import Cookies from 'universal-cookie';
import I from 'immutable';
import { TOAST } from "../helpers/NotificationHelper";

// import MobileDetect from 'mobile-detect'

// const MD = new MobileDetect(window.navigator.userAgent)
// export const isMobile = () => MD.mobile()

export const setCookies = (name, value, expiry) => {
  const cookies = new Cookies();
  cookies.set(name, value, { maxAge: expiry || 1000000, path: '/' })
}
export const clearCookies = () => {
  const cookies = new Cookies();
  // cookies.set('token', '')
  cookies.remove('token')
  let c = cookies.getAll()
  Object.keys(c).forEach(element => {
    cookies.remove(element)
  })
  // Cookies.remove('token', { path: '/' })
}

export const getToken = () => {
  const cookies = new Cookies();
  return cookies.get('token')
}
export const getEventId = () => {
  const cookies = new Cookies();
  return cookies.get('eventId')
}

export const getEventLogo = () => {
  const cookies = new Cookies();
  return cookies.get('event_logo')
}
export const getCookies = (name) => {
  const cookies = new Cookies();
  return cookies.get(name)
}

export const updateListItem = (list, data, insert = false, id = 'id') => {
  let items = list
  let index = -1
  let existingItem = items.find((item, key) => {
    if (item.get(id).toString() === data.get(id).toString()) {
      index = key
      return true
    }
    return false
  })

  if (existingItem) {
    items = items.update(index, I.Map(), value =>
      value.mergeWith((oldVal, newVal) => {
        return newVal
      }, data)
    )
  } else {
    if (insert === true) {
      items = items.unshift(data)
    } else {
      console.log("Item not found")
    }

  }
  return items
}



export async function fetchData() {
  let t = await getToken();
  try {
    const response = await fetchSpeakers(t)
    setSpeakers(asImmutable(response))
    return response;
  } catch (error) {
    TOAST.error(error.message);
    return null;
  }
}

export const asImmutable = data => (I.isImmutable(data) ? data : I.fromJS(data))


export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = (error) => reject(error);
  });

export const SnakeToCamel = str =>
  str && str.length > 0 ? (str.charAt(0).toUpperCase() + str.slice(1)).replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', ' ')
      .replace('_', ' ')
  ) : null;



export const errorsToForm = (e) => {
  const errors = asImmutable(e.response || {}).getIn(['data', 'errors'], I.List())
  if (errors.size > 0) {
    let c = Object.keys(errors.toJS()).map(x => {
      return ({ name: x, errors: errors.get(x, []).toJS() })
    })

    return c
  }

  return []
}

export const getHref = (x) => {

  if (x.href) {
    return x.href
  }

  switch (x.type) {

    case "exhibitor": return '/exhibitors/profile'
    case "exhibitor_badges": return '/exhibitors/badge'
    case "power_orders": return '/exhibitors/power_orders'
    case "product_index": return '/exhibitors/product_index'
    case "dynamic_form": return '/exhibitors/forms/' + x.edit_status.dynamic_form_group_id
    // default: return "#"
    default: return null
  }
}


export const mediaSizes = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};



export const makeError = (x) => {
  return x?.response?.status == 401 ? {
    redirect: {
      destination: "/logout",
    }
  } : x?.response?.status == 435 ? { //redirect manually
    redirect: {
      destination: x?.response?.data?.redirect,
    }
  } :
    x?.response?.status == 404 ?
      { notFound: true } :
      {
        props: {
          error: {
            message: (x?.response?.data?.message),
            code: (x?.response?.status)
          }
        }
      }
}

export const youtube_parser = (url) => {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return (match && match[7].length == 11) ? match[7] : false;
}

export function truncate(str) {
  return str?.length > 250 ? str.substring(0, 250) + "..." : str;
}

const debugEnabled = process.env.NEXT_PUBLIC_APP_DEBUG_ENABLE === 'true';

export const logger = (...args) => {
  if (debugEnabled) {
    console.log(...args);
  }
};


// utils/polyfilsResolver.js

/**
 * Polyfill for Promise.withResolvers if it's not available.
 */
export function polyfillPromiseWithResolvers() {
  if (!Promise.withResolvers) {
    Promise.withResolvers = function () {
      let resolve;
      let reject;

      const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });

      return { promise, resolve, reject };
    };
  }
}