import { CloseOutlined, DownOutlined, MenuOutlined } from '@ant-design/icons';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { getCookies, getEventLogo, getToken } from '../helpers/utils';
import { getMenus } from './api/exhibitors';
import AppDownloadBanner from './components/AppLinksFloater'
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core'; // Prevent fontawesome from adding its CSS
import { Collapse } from 'antd';
config.autoAddCss = false; /* eslint-disable import/first */


// const fetcher = async (url) => fetch('/api/menus', {
//     headers: {
//         "Content-type": "application/json;charset=UTF-8",
//         "Authorization": "Bearer " + (await getToken())
//     }
// }).then((res) => res.json());




const LayoutWrapper = ({ eventId, children }) => {


    const [expand, setExpand] = useState(false)




    const [logo, setLogo] = useState()
    const [Cname, setCName] = useState()

    useEffect(() => {
        setCName(getCookies('name'))
        setLogo(getEventLogo())
    })

    // useEffect(() => {
    //     if (!expand && window.innerWidth > 768) {
    //         setExpand(window.innerWidth > 768)
    //     } else {
    //         setExpand(window.innerWidth > 768)
    //     }
    // }, [])

    useEffect(() => {
        setExpand(window.innerWidth > 768);
        const handleResize = () => {
            setExpand(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const router = useRouter(false);

    const menuItems1 = [
        {
            "href": "/",
            "title": "Home"
        },
        // {
        //     "href": "/programs",
        //     "title": "Programs"
        // },
        // {
        //     "href": "/speakers",
        //     "title": "Speakers"
        // },
        // {
        //     "href": "/exhibitors",
        //     "title": "Exhibitors"
        // },

        {
            "href": "/exhibitors/profile",
            "title": "Exhibitor Profile"
        },
        {
            "href": "/exhibitors/uploads/files",
            "title": "Important Information "
        },
        {
            "href": "/exhibitors/uploads/certificates",
            "title": "Certificates & Invoices"
        },
        {
            "href": "/exhibitors/product_index",
            "title": "Product Index"
        },
        {
            "href": "/exhibitors/forms/10",
            "title": "Brand Promotion"
        },
        {
            "href": "/exhibitors/forms",
            "title": "Forms"
        },

        {
            "href": "/exhibitors/cart",
            "title": "Additional Requirement"
        },
        {
            "href": "/exhibitors/orders",
            "title": "Orders"
        },
        {
            "href": "/exhibitors/booth",
            "title": "Booth Design"
        },
        // {
        //     "href": "/exhibitors/directory_map_board",
        //     "title": "Directory Map Board"
        // },
        {
            "href": "/exhibitors/power_orders",
            "title": "Power Orders"
        },
        {
            "href": "/exhibitors/change_password",
            "title": "Change Password"
        },
        {
            "href": "/exhibitors/badge",
            "title": "Exhibitor Badge"
        },
        {
            "href": "/exhibitors/support",
            "title": "Help & Support"
        },

        // {
        //     "href": "/myprofile",
        //     "title": "My Profile"
        // },
        // {
        //     "href": "/delegates",
        //     "title": "Delegates"
        // },

        // {
        //     "href": "/downloads",
        //     "title": "Downloads"
        // },
        // {
        //     "href": "/logout",
        //     "title": "Logout"
        // }

    ]

    // const id = 1//await getEventId()
    const items = typeof window !== 'undefined' ? localStorage.getItem('menu_' + eventId) : null


    // let data = items ? JSON.parse(items).data : null

    const [menuItems, setMenuItems] = useState([])
    const [active, setActive] = useState()
    useEffect(() => {
        // setMenuItems(items ? JSON.parse(items).data : null)
    }, [items])
    // const menuItems = Config.MenuItems

    const [h, H] = useState(true)

    const MapItem = (items, str) => items.map((item, i) => {
        item['id'] = str + '.' + i
        if (item.children) {
            item['children'] = MapItem(item['children'], str + '.' + i)
        }
        return item
    });

    const __getMenus = async () => {
        const d = await getMenus(await getToken(), eventId)
            .then(x1 => {
                let x = MapItem(x1, '1')
                let data = JSON.stringify({ "data": x, "time": Date.now() })
                localStorage.setItem('menu_' + eventId, data)
                setMenuItems(x)
            })
            .catch(x => {
                console.log(x);
            })
    }

    const similar = (a, b) => {
        var equivalency = 0;
        var minLength = (a.length > b.length) ? b.length : a.length;
        var maxLength = (a.length < b.length) ? b.length : a.length;
        for (var i = 0; i < minLength; i++) {
            if (a[i] == b[i]) {
                equivalency++;
            }
        }


        var weight = equivalency / maxLength;
        return (weight * 100) //+ "%";
    }

    const similarParent = (href, menuItems) => {
        let weight = 0
        let itemIndex = ''

        for (let i = 0; i < menuItems.length; i++) {
            const menu = menuItems[i]
            
            if(menu.children && href != '' && href != '#'){
                let c = similar(href, menu.href)
                if (c > weight) {
                    weight = c
                    itemIndex = menu['id']
                }
            }

            if (menu.children) {
                let d = similarParent(href, menu.children)
                if (d.weight > weight) {
                    weight = d.weight
                    itemIndex = d.itemIndex
                }
            } else {
                let c = similar(href, menu.href)
                if (c > weight) {
                    weight = c
                    itemIndex = menu['id']
                }
            }
        }
        return { itemIndex, weight }
    }
    const findMenu = (href) => {
        let weight = 0
        let itemIndex = ''
        let d = similarParent(href, menuItems)
        itemIndex = d.itemIndex
        weight = d.weight
        // itemIndex, weight = similarParent(href, menuItems)
        // if (itemIndex >= 0) {
        //     if (!(menuItems[itemIndex].href).startsWith(href)) {
        //         itemIndex = -1
        //     }
        // }
        setActive(itemIndex)

    }





    useEffect(() => {

        if (h) {
            H(false)
            if (items) {
                let store = JSON.parse(items)

                let now = Date.now()
                let delay = (5 * 60 * 1000)


                if ((store.time + delay) < now || store.time > now) {
                    localStorage.removeItem('menu_' + eventId)
                    __getMenus()
                } else {
                    setMenuItems(store.data)
                    return
                }
                return
            }
            __getMenus()
        }
    }, [h])

    useEffect(() => {
        findMenu(router.asPath)
    }, [menuItems])


    const handleClick = (event) => {
        const submenu = event.target.parentNode.querySelector('ul');
        if (submenu) {
            // remove 'expanded' class from other submenus
            const expandedSubmenus = document.querySelectorAll('.expanded');
            for (const expandedSubmenu of expandedSubmenus) {
                if (expandedSubmenu !== submenu) {
                    expandedSubmenu.classList.remove('expanded');
                }
            }
            // toggle 'expanded' class on the clicked submenu
            submenu.classList.toggle('expanded');
            const icon = event.target.parentNode.querySelector('DownOutlined');
            if (icon) {
                icon.classList.toggle('rotated');
            }
        }
    }

    const Menu = ({ menuData }) => {

        return (
            <ul className='expanded'>
                {menuData.map((item, i) => (
                    <li className='w-1001 ml-2 mr-2 p-1' key={item.href} onClick={handleClick}>
                        {item.children ? <Collapse defaultActiveKey={['1']}
                            rootClassName='px-0'
                            style={{ padding: 0,paddingBlock:0 }}
                            items={[{
                                key: 1, 
                                className:"px-0",
                                label: <a href={item?.href} className='text-black no-underline'>{item?.title}</a>,
                                children: <Menu menuData={item?.children} />
                            }]} collapsible="icon" ghost expandIconPosition="end"
                            className={`px-0 __collapse_header ${active && active.startsWith(item?.id) ? '__active_collapse' : ''}`} /> :
                            <a href={item?.href}
                                className={`flex p-2 pl-4 text-black rounded cursor-pointer ${active && active.startsWith(item?.id) ? '__active' : ''}`}
                            >
                                {item?.title}
                            </a>
                        }
                    </li>
                ))}
            </ul>
        );
    }



    // const menuItems1 = typeof process.env.MENU == 'string' ? JSON.parse(process.env.MENU) : []


    return (
        <div className='min-h-screen flex flex-col bg-slate-100 '>
            <div className='flex flex-col md:flex-row flex-1 relative'>
                <aside className={`__testNav __sider __scrollbar ${expand ? 'w-full' : 'w-0'} md:w-60 overflow-hidden md:sticky`}

                    style={{ height: expand ? 'calc(100vh - 0px)' : 0, left: 0, top: 0, bottom: 0, position: 'absolute', zIndex: 5 }}
                >

                    <div className='flex justify-center items-center text-center relative' style={{ height: 100 }}>
                        {/* <CloseOutlined className='absolute top-0 right-0 m-5  md:hidden text-black' onClick={() => {
                            setExpand(false)
                        }} /> */}
                        {logo ? <img src={logo} className="mx-auto" style={{ maxWidth: 100, maxHeight: 100 }} /> : <div></div>}
                    </div>
                    <nav className='h-full0 overflow-auto sticky' style={{ height: 'calc(100vh - 100px)', zIndex: '999' }}>
                        <Menu menuData={menuItems} />
                        {/* <ul suppressHydrationWarning={true}>
                            {(menuItems || [])?.map(({ href, title }, x) => {
                                return <li className='m-2' key={title}>
                                    <a href={href}
                                        className={`flex p-2 pl-4 text-black rounded cursor-pointer ${x == active ? '__active' : ''}`}>
                                        {title}
                                    </a>
                                </li>
                            })}
                            <li className={`m-2 ${menuItems && menuItems.length > 0 ? "" : 'hidden'}`} key={'logout'} onClick={() => router.push('/logout')}>
                                <a className={`flex p-2 pl-4 text-black rounded cursor-pointer`}>Logout
                                </a>
                            </li>

                        </ul> */}
                    </nav>
                </aside>

                <main className='flex-1' style={{ width: '100vw' }}>

                    <header className='__header px-2 py-1 sticky top-0 h-16 flex justify-start item-center font-semibold uppercase lg:z-[100]'
                        style={{ left: 0, right: 0 }}>
                        <div className="flex justify-between w-100 align-items-center ">
                            <div></div>
                            <div className='items-center flex pr-2 lg:pr-12'>{Cname}</div>
                        </div>
                        <div className='flex md:hidden mx-5 items-center'><MenuOutlined onClick={() => setExpand(!expand)} /></div>
                    </header>
                    {/* <ConfigProvider theme={{ //antd theme configs
                        token: {
                            colorPrimary: '#026fa6'
                        },
                        components: {
                            Carousel: {
                                arrowOffset: 16
                            },
                            Tag: {
                                defaultBg: '#026fa6',
                                defaultColor: 'gray'
                            },
                        },
                    }}> */}

                    {children}
                    {<AppDownloadBanner autoClose={true} delay={7000} />}
                </main>
            </div>
        </div>
    );
}



export default LayoutWrapper;